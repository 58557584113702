import React, { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Box, Button, ButtonGroup, Container, CssBaseline, Icon, TextField, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link } from "react-router-dom";
import { BaseUrl, ConfirmPasswordReset } from '../constants/constants';
import axios, * as others from 'axios';
import useFullPageLoader from './hooks/useFullPageLoader';
import Iconify from '../components/Iconify';
import { IconButton, InputAdornment } from '@mui/material';
import swal from 'sweetalert';
 
const ResetConfirmPassword = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  let token=searchParams.get("token");
  const decodedToken = JSON.parse(atob(token.split('.')[1]));
  const [email, setEmail] = useState(decodedToken.email_address);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
        swal({
            title: "Error",
            text: "Passwords do not match",
            icon: "error",
          });
      return;
    }
    showLoader();
    axios.post(BaseUrl + ConfirmPasswordReset, {
      token: token,
      new_password: password
    })
    .then((response) => {
        hideLoader();
        swal({
            icon: 'success',
            title: 'Password Updated Successfully',
            text: '',
        }).then(function() {
            window.location.href = '/'
        });
    })
    .catch((error) => {
      hideLoader();
      swal({
        title: "Error",
        text: error.response ? error.response.data.message : "An error occurred",
        icon: "error",
      });
    });
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 15,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <form onSubmit={handleSubmit}>
            <Typography component="h1" variant="h5">
              Reset Password
            </Typography>
            <Box sx={{ mt: 1, width: 400 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                disabled
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="password"
                label="Password"
                name="password"
                autoComplete="password"
                autoFocus
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="confirm password"
                label="Confirm Password"
                name="confirm password"
                autoComplete="confirm password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                        <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <ButtonGroup sx={{ mt: 3, mb: 2, width: 250 }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                >
                  Reset
                </Button>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ left: 150 }}
                >
                  <Link to="/" style={{ textDecoration: 'none', color: 'white'}}>
                    Cancel
                  </Link>
                </Button>
              </ButtonGroup>
            </Box>
            </form>
          </Box>
      </Container>
      {loader}
    </>
  );
};

export default ResetConfirmPassword;