// export const BaseUrl = 'http://localhost:7000'

export const BaseUrl = 'https://saas.khika.in/api'

export const SignUpEndpoint = '/auth/registration'

export const SignInEndpoint = '/auth/login'

export const ConfirmEmailEndpoint = "/auth/verify-email/"

export const AgentsSummary = "/connectors/workstation/devices/summary"

export const DevicesList = "/connectors/workstation/devices"

export const SupportedPlatform = "/connectors/workstation/supported_platforms"

export const InstallCmd = "/connectors/workstation/install_cmd"

export const ListDashboards = "/connectors/workstation/dashboards"

export const ActiveAlerts = "/tenant/alerts?alert_state=ACTIVE"

export const IngestedDataTotal = "/tenant/stats"

export const ConnectorsTotal = "/connectors/workstation"

export const TenantDetails = "/tenant/"

export const MitreDashboardId = "89420cb0-59c5-11ed-bdbd-291ae6eb36cb"

export const VulnerabilitiesDashboardId = "99133470-5ab0-11ed-bdbd-291ae6eb36cb"

export const PcidssDashboardId = "ffe0bf70-59e7-11ed-bdbd-291ae6eb36cb"

export const IntegrityDashboardId = "8eb08130-5b65-11ed-bdbd-291ae6eb36cb"

export const CisDashboardId = "09b62360-5eb0-11ed-bdbd-291ae6eb36cb"

export const MultipleLoginId = "9384e980-5a9d-11ed-bdbd-291ae6eb36cb"

export const LogonFailures = "0fa1c5f0-5aa4-11ed-bdbd-291ae6eb36cb"

export const HostwiseLogon = "c6a620f0-5ab5-11ed-bdbd-291ae6eb36cb"

export const DeleteAgent = "/connectors/workstation/device?agent_id="

export const AgentOs = "/connectors/workstation/devices/summary/os"

export const ListAlerts = "/connectors/workstation/alerts?start_index="

export const ActiveResponse = "/tenant/active_response/"

export const AlertsList = "/connectors/workstation/alerts"

export const ConnectorsDetails = "/connectors/"

export const SyslogDevices = "/connectors/syslog/device"

export const CloudDevices = "/connectors/cloud/accounts"

export const PasswordReset = "/auth/password_reset/"

export const ConfirmPasswordReset = '/auth/password_reset/confirm'

export const PlaybookData = '/alerts/rules_playbook'

export const ConfigRule = '/alerts/configure_rule'

export const SocMappedUser = '/user/list_soc_mapped_users'