import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { Button, MenuItem, Select, FormControl, InputLabel, CircularProgress, Box, Typography } from '@mui/material';
import { BaseUrl } from '../constants/constants';
import swal from 'sweetalert';

const DataManagement = () => {
  const [filterStatus, setFilterStatus] = useState('snapshot');
  const [rows, setRows] = useState([]);
  const [count, setCount] = React.useState('');
  const [loading, setLoading] = useState(true);
  const [restoreLoading, setRestoreLoading] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(null);
  const user_details = JSON.parse(window.localStorage.getItem("user_details"));
  const token = user_details.data.accessToken;
  const tenantname = JSON.parse(window.localStorage.getItem("tenant_name"));
  const tenentDetails = JSON.parse(window.localStorage.getItem("tenant_details"));
  const liveretention = tenentDetails.live_retention_days;
  const archieveretention = tenentDetails.archive_retention_days;
  const user_role = window.localStorage.getItem("user_role");

  const handleRestore = async (date) => {
    if (count >= 5 ) {
        swal({
          icon: 'warning',
          title: 'Limit Exceeded',
          text: 'The restoration limit of 5 records has been reached. Please delete previously restored records to restore new ones.',
        });
        return;
    }

    setRestoreLoading(date);
    const data = JSON.stringify({
      "dates_list": [date]
    });

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: BaseUrl + '/archival/req_snap_restore',
      headers: { 
        'Content-Type': 'application/json', 
        'Authorization': `Bearer ${token}`
      },
      data: data
    };

    try {
      const response = await axios.request(config);
      swal({
        icon: 'success',
        title: 'Index Restoration Initialized.'
      }).then(function() {
        window.location.reload();
      });
    } catch (error) {
      
    } finally {
      setRestoreLoading(null);
    }
  };

  const handleDelete = async (date) => {
    swal({
      title: 'Are you sure you want to delete the restored index?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    })
    .then(async (willDelete) => {
      if (willDelete) {
        setDeleteLoading(date);
  
        const data = JSON.stringify({
          "dates_list": [date],
          "restore_status": "snapshot",
          "tenant_name": tenantname
        });
  
        const config = {
          method: 'delete',
          maxBodyLength: Infinity,
          url: BaseUrl + '/archival/snap_old_index',
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': `Bearer ${token}`
          },
          data: data
        };
  
        try {
          const response = await axios.request(config);
          swal({
            icon: 'success',
            title: `Restored data for ${date} has been deleted.`,
          }).then(function() {
            window.location.reload();
          });
          
        } catch (error) {
          swal({
            icon: 'error',
            title: 'Failed to delete restored data. Please try again later.',
          });
        } finally {
          setDeleteLoading(null);
        }
      }
    });
  };
  
  const fetchData = async () => {
    try {
      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: BaseUrl + '/archival/get_snap_list',
        headers: { 
          'Authorization': `Bearer ${token}`
        }
      };

      const response = await axios.request(config);
      const ArchivalList = response.data.map((element, index) => {
          let formattedCreatedAt = element.restore_status === 'snapshot'
              ? "-"
              : (element.restored_at !== "snapshot"
                  ? new Date(element.restored_at).toLocaleString()
                  : "-");

          return {
              id: index + 1,
              date: element.index_date,
              status: element.restore_status,
              restored_at: formattedCreatedAt
          };
      });
      
      ArchivalList.sort((a, b) => new Date(b.date) - new Date(a.date));
      setRows(ArchivalList);
      const completedCount = ArchivalList.filter(item => item.status === 'completed').length;
      setCount(completedCount);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const filteredRows = filterStatus
    ? rows.filter((row) => row.status === filterStatus)
    : rows;

  const columns = [
    { field: 'date', headerClassName: 'super-app-theme--header', headerName: 'Index Date', flex: 0.3, cellClassName: () => 'word-wrap' },
    { field: 'status', headerClassName: 'super-app-theme--header', headerName: 'Restore Status', flex: 0.3, cellClassName: () => 'word-wrap',
      renderCell: (params) => (
        <div>
          {params.value === 'snapshot' ? 'Available to restore' : params.value === 'delete_in_progress' ? 'Deletion in Progress' : params.value === 'completed' ? 'Restored' : 'Restore in Progress'}
        </div>
      )
    },
    { field: 'restored_at', headerClassName: 'super-app-theme--header', headerName: 'Restored At', flex: 0.3, cellClassName: () => 'word-wrap' },
    {
      field: 'action',
      headerClassName: 'super-app-theme--header',
      headerName: 'Action',
      flex: 0.2,
      renderCell: (params) => (
        <div>
          {params.row.status === 'snapshot' ? (
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => handleRestore(params.row.date)}
              disabled={restoreLoading === params.row.date || user_role === 'user_ro'}
            >
              {restoreLoading === params.row.date ? <CircularProgress size={24} /> : 'Restore'}
            </Button>
          ) : params.row.status === 'completed' ? (
            <Button
              size="small"
              variant="outlined"
              color="error"
              onClick={() => handleDelete(params.row.date)}
              disabled={deleteLoading === params.row.date || user_role === 'user_ro'}
            >
              {deleteLoading === params.row.date ? <CircularProgress size={24} /> : 'Delete'}
            </Button>
          ) : params.row.status === 'delete_in_progress' ? (
            'Deletion in Progress'
          ) : (
            'In Progress'
          )}
        </div>
      ),
    },
  ];

  const handleRefresh = () => {
    setLoading(true);
    fetchData();
  };

  return (
    <div style={{ height: '70vh', width: '100%', marginTop: '2%' }}>
      <style>
        {`
          .MuiDataGrid-columnHeader {
            background-color: green;
            color: white;
          }
          .MuiDataGrid-cell {
            color: black;
          }
          .MuiDataGrid-footerContainer {
            background-color: lightgray;
          }
          .MuiDataGrid-row:nth-of-type(odd) {
            background-color: lightyellow;
          }
          .MuiDataGrid-row:nth-of-type(even) {
            background-color: white;
          }
        `}
      </style>
      <Box display="flex" alignItems="center" mb={2}>
        <FormControl style={{ width: '200px', marginLeft: '1%' }}>
          <InputLabel id="status-filter-label">Restore Status</InputLabel>
          <Select
            labelId="status-filter-label"
            id="status-filter"
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
            label="Restore Status"
          >
            <MenuItem value="completed">Restored</MenuItem>
            <MenuItem value="in_progress">In Progress</MenuItem>
            <MenuItem value="snapshot">Available to Restore</MenuItem>
          </Select>
        </FormControl>
        <Box ml={3} 
        sx={{ 
          border: '1px solid #000', 
          padding: '8px', 
          borderRadius: '4px', 
          minWidth: '150px',
          backgroundColor: 'green',
          color: 'white'
        }}>
          <Typography variant="subtitle1">Live Retention: {liveretention} Days</Typography>
        </Box>
        <Box ml={3} 
        sx={{ 
          border: '1px solid #000', 
          padding: '8px', 
          borderRadius: '4px', 
          minWidth: '150px',
          backgroundColor: '#17a2b8',
          color: 'white'
        }}>
          <Typography variant="subtitle1">Archival Retention: {archieveretention} Days</Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginLeft: '40%' }}>
          <Button variant="contained" color="primary" onClick={handleRefresh}>
            Refresh
          </Button>
        </Box>
      </Box>
      <DataGrid
        rows={filteredRows}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[5]}
        loading={loading}
        disableSelectionOnClick
      />
    </div>
  );
};

export default DataManagement;