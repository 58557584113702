import React, { useState } from 'react';

const Misp = () => {
    const selected_tenant = localStorage.getItem("selectedtenant");
    const [tenantname, setTenantname] = useState(
      selected_tenant || JSON.parse(window.localStorage.getItem("tenant_name"))
    );
    const tenant_ktoken = localStorage.getItem("tenant_token");
    const user_details = JSON.parse(window.localStorage.getItem("user_details"));
    const kibanaToken = tenant_ktoken ? tenant_ktoken : user_details.data.kibana_token;
  
    const iframeSrc = "https://saas.khika.in/kibana/app/dashboards?security_tenant=" + tenantname + "&auth_token=" + kibanaToken + "#/view/52fd1a70-c1c2-11ef-b0da-23e0724711ff?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'Threat%20Intel%20Overview',viewMode:view)&embed=true&show-time-filter=true";
    
    return (
      <div style={{ height: '100vh', width: '100%' }}>
        <iframe
          src={iframeSrc}
          width="100%"
          height="100%"
          frameBorder="0"
          title="Threat Intel Overview Dashboard"
        ></iframe>
      </div>
    );
  };
  
  export default Misp;
  