import React, { useEffect, useState } from 'react'
import Navbar from './Navbar'
import axios, * as others from 'axios';
import {
  Card, Box, Button, Typography, Checkbox, FormControlLabel, Menu, Dialog, DialogTitle, DialogContent, DialogActions, Tooltip, Table,
  TableBody,
  TableRow,
  TableCell,
  CardContent,
} from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { AlertsList, BaseUrl } from '../constants/constants';
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { CheckCircle } from '@mui/icons-material';
import { fetchUsers } from '../api/api';

const Alerts = () => {
  const role = localStorage.getItem("user_role")?.replace(/"/g, "").trim();

  const [selectedAlert, setSelectedAlert] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleViewDetails = (alertData) => {
    setSelectedAlert(alertData);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedAlert(null);
  };

  const columns = [
    {
      field: 'timestamp',
      headerName: 'Date Time',
      flex: 0.6,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <div>{params.value}</div>
        </Tooltip>
      ),
    },
    {
      field: 'agent_name',
      headerName: 'Agent Name',
      flex: 0.4,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <div>{params.value}</div>
        </Tooltip>
      ),
    },
    {
      field: 'ruleid',
      headerName: 'Rule Id',
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <div>{params.value}</div>
        </Tooltip>
      ),
    },
    {
      field: 'rule_info',
      headerName: 'Rule Info',
      flex: 0.5,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <div>{params.value}</div>
        </Tooltip>
      ),
    },
    {
      field: 'level',
      headerName: 'Level',
      flex: 0.4,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <div>{params.value}</div>
        </Tooltip>
      ),
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <div>{params.value}</div>
        </Tooltip>
      ),
    },
    {
      field: 'viewDetails',
      headerName: 'View Details',
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          size="small"
          sx={{
            padding: "2px 8px",
            fontSize: "12px",
            fontWeight: 500,
            borderRadius: "4px",
            textTransform: "none",
            minWidth: "80px",
          }}
          onClick={() => handleViewDetails(params.row)}
        >
          View Details
        </Button>
      ),
    },
  ];

  const tenant_access_switched = localStorage.getItem("tenant_access_switched");

  const user_details = JSON.parse(window.localStorage.getItem("user_details"));

  const tenant = JSON.parse(localStorage.getItem("tenant_details"));
  const og_tenant = tenant.tenant_name;

  const [token, setToken] = useState(tenant_access_switched ? tenant_access_switched : user_details.data.accessToken);

  const now = new Date().toISOString();

  const [startdate, setStartDate] = React.useState(dayjs(new Date(Date.now() - 24 * 60 * 60 * 1000)));
  const [enddate, setEndDate] = React.useState(dayjs(now));

  const [page, setPage] = React.useState(0);
  const [data, setData] = React.useState({});
  const [filtercount, setFilterCount] = React.useState([]);
  const [pageSize, setPageSize] = React.useState(10);
  const [loading, setLoading] = React.useState(false);
  const [totalRows, setTotalRows] = React.useState(0);

  React.useEffect(() => {
    if (token) {
      fetchData('');
    }
  }, [token]);

  React.useEffect(() => {
    fetchData('');

  }, []);

  const [filterjson, setFilterJson] = useState(
    {
      // "collapse": { "field": "rule.id" },
      "size": 10000,
      "sort": [
        { "timestamp": "desc" }
      ],
      "query": {
        "bool": {
          "filter":
            [
              // {"range": {"rule.level": {"gte": 8, "lt": 12}}}, 
              {
                "terms": {
                  "rule.id": [
                    301001, 301004, 301005, 301006, 301002, 301003, 301007, 301008, 100601, 18110, 60109, 18111, 18112, 60111, 18116, 18118, 60117, 63103, 18127, 
                    60121, 60132, 60133, 60138, 60139, 60784, 60785, 63104, 92213, 92655, 92064, 92104, 18130, 18138, 60923, 18128, 5105, 5106, 5111, 5112, 
                    5109, 5110, 5113, 5130, 5134, 5135, 5137, 5138, 5141, 5104, 5103, 80731, 80732, 5132, 5133, 5108, 2901, 2902, 2903, 2904, 2942, 2944, 2941, 
                    2945, 2932, 2934, 2937, 2938, 2939, 80725, 80722, 80723, 80726, 92601, 92603, 92604, 92605, 80713, 80714, 80702, 80704, 80711, 80721, 80731, 
                    80732, 80740, 92602, 504, 505, 506, 601, 603, 605, 651, 653, 655, 100100, 100101, 100102, 100103, 100106, 100107, 100108, 100109, 100110, 100111, 
                    100112, 100113, 100114, 100115, 100116, 100117, 100122, 100123, 100124, 100125, 100118, 100119, 100120, 100121, 100130, 100131, 100132, 100133, 
                    519, 531, 533, 580, 513, 593, 4810, 64025, 80127, 81620, 81631, 80129, 81613, 658, 4101, 81634, 90554, 90564, 67005, 67006, 67007, 67008, 81605, 81615, 
                    81617, 4339, 4342, 4508, 4509, 64023, 64024, 64027, 91520, 91521, 91522, 91525, 4811, 4151, 4551, 4851, 87702, 100017, 100018, 100014, 100015, 100016, 
                    100019, 11209, 92044, 7310, 7320, 7410, 7504, 7509, 7510, 7610, 7613, 7705, 7707, 7708, 40113, 62113, 62114, 62115, 62122, 62123, 62126, 62131, 62144, 
                    62149, 62152, 62153, 62157, 62160, 62174, 62176, 62178, 62606, 62609, 62612, 62616, 63603, 65500, 65501, 83001, 87101, 87102, 87103, 87105, 100303, 
                    100306, 100310, 100314, 100327, 100328, 100329, 100330, 100331, 100332, 100333, 100334, 100335, 100336, 100337, 100338, 100339, 100340, 100341, 
                    100342, 100343, 100344, 100345, 100346, 100347, 100348, 100349, 100350, 100351, 100352, 100353, 100354, 100355, 100356, 100358, 100360, 100362, 
                    100363, 100364, 100365, 100366, 31101, 31120, 31122, 31315, 30107, 30118, 30200, 30201, 31516, 31502, 31503, 31504, 31505, 31506, 31507, 31508, 31512, 
                    31513, 30112, 31168, 31169, 31154, 31161, 31162, 31163, 31533, 31151, 31152, 31153, 31105, 91542, 91543, 91545, 91610, 91613, 91616, 91719, 91547, 91548, 
                    91557, 91566, 91570, 91579, 91612, 91620, 91704, 91705, 91708, 91715, 91569, 91589, 91605, 91615, 91622, 91724, 91556, 91575, 91720, 91722, 91700, 80326, 
                    80327, 80361, 80442, 80456, 80457, 99080, 99081, 99082, 80203, 80250, 80254, 80328, 80368, 80370, 99026, 80252, 80369, 80371, 80443, 99027, 99028, 99029, 
                    99030, 99021, 87802, 87811, 87813, 91545, 91709, 91711, 91714, 65053, 65054, 65055, 65056, 65057, 65058, 65059, 65060, 65061, 65062, 65063, 65064, 65067, 
                    65068, 65069, 65072, 65073, 65023, 65048, 65031, 65037, 65008, 65015, 65044, 65018, 65024, 65027, 65030, 65010, 65017, 65020, 65046, 50108, 50511, 85756, 
                    85758, 85759, 85752, 88101, 85761, 50512, 50580, 50581, 85760, 50520, 50521, 1004, 1006, 2501, 2504, 2502, 1003, 1001, 1002, 12101, 12109, 40105, 40106, 
                    100013, 40104, 5707, 5714, 40102, 40501, 2963, 2833, 5901, 5902, 5904, 11251, 12149, 40111, 40112, 91100, 91111, 91169, 91361, 91448, 65601, 65615, 91170, 
                    91110, 91151, 91164, 91184, 91222, 91228, 91249, 91250, 91254, 91255, 91256, 91264, 91277, 91399, 91402, 91418, 91103, 91363, 91241, 91315, 91367, 61618, 
                    61627, 61628, 61630, 61636, 184666, 184706, 184716, 184736, 184746, 184776, 99553, 99585, 99628, 99632, 99635, 99636, 99637, 99506, 99534, 99538, 99606, 
                    99566, 99576, 99626, 99630, 99530, 99536, 99564, 99631, 99653, 23503, 22402, 22407, 23504, 22405, 23505, 22406, 23506, 19113, 19103, 19111, 19123, 19102, 
                    19151, 19153, 100501, 100502, 100126, 100127, 100128, 100129
                  ]
                }
              },
              {
                "range": {
                  "timestamp": {
                    "gte": startdate,
                    "lt": enddate
                  }
                }
              }
            ]
        }
      },
      "aggs": {
        "rule_level_aggregation": {
          "terms": {
            "field": "rule.level",
            "size": 20
          }
        }
      }
    }
  );


  const fetchData = async page => {
    setLoading(true);
    var data = JSON.stringify(filterjson);

    var config = {
      method: 'post',
      url: BaseUrl + AlertsList + page,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        setFilterCount(response.data.aggregations.rule_level_aggregation.buckets);
        setData(response.data.hits.hits.map(element => {
          const timestamp = new Date(element._source.timestamp).toLocaleString();
          return {
            id: element._source.id,
            agent_id: element._source.agent?.id || '',
            timestamp: timestamp,
            agent_name: element._source.agent?.name || '',
            description: element._source.rule?.description || '',
            level: element._source.rule?.level || '',
            ruleid: element._source.rule?.id || '',
            rule_info: element._source.rule?.mitre?.technique || '',
            agent_ip: element._source.agent?.ip || '',
            agent_os: element._source.agent?.labels?.os_type || '',
            eventID: element._source.data?.win?.system?.eventID || '',
            system_level: element._source.data?.win?.system?.level || ''
          }
        }))
        setTotalRows(response.data.hits.hits.length);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }

  const [dropdownlist, setDropDownList] = useState([]);

  React.useEffect(() => {
    if (token) {
      dropdownData('');
    }
  }, [token]);

  React.useEffect(() => {
    dropdownData('');

  }, []);

  const [dropdownjson, setDropdownJson] = useState(
    {
      "aggs": {
        "components": {
          "terms": { "field": "agent.name", "size": 1000 }
        }
      },
      "size": 0
    }
  );

  const dropdownData = async page => {

    var data = JSON.stringify(dropdownjson);

    var config = {
      method: 'post',
      url: BaseUrl + AlertsList + page,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        setDropDownList(response.data.aggregations.components.buckets);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const TodayButton = ({ setStartDate }) => {
    const handleClick = () => {
      const date = new Date();
      date.setHours(0, 0, 0, 0);
      setStartDate(date);
      setEndDate(now)
      updateTimeDate(date);
      handleClose();
    };
    return <MenuItem onClick={handleClick}>Today</MenuItem>;
  };

  const ThisWeek = ({ setStartDate }) => {
    const handleClick = () => {
      const now = new Date();
      const thisWeek = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
      setStartDate(thisWeek);
      setEndDate(now)
      updateTimeDate(thisWeek);
      handleClose();
    };

    return <MenuItem onClick={handleClick}>This Week</MenuItem>;
  };

  const Last15MinsButton = ({ setStartDate }) => {
    const handleClick = () => {
      const now = new Date();
      const last15Minutes = new Date(now.getTime() - 15 * 60 * 1000);
      setStartDate(last15Minutes);
      setEndDate(now)
      updateTimeDate(last15Minutes);
      handleClose();
    };

    return <MenuItem onClick={handleClick}>Last 15 Mins</MenuItem>;
  };

  const Last30MinsButton = ({ setStartDate }) => {
    const handleClick = () => {
      const now = new Date();
      const last30Minutes = new Date(now.getTime() - 30 * 60 * 1000);
      setStartDate(last30Minutes);
      setEndDate(now)
      updateTimeDate(last30Minutes);
      handleClose();
    };

    return <MenuItem onClick={handleClick}>Last 30 Mins</MenuItem>;
  };

  const Last1Hour = ({ setStartDate }) => {
    const handleClick = () => {
      const now = new Date();
      const last1Hour = new Date(now.getTime() - 1 * 60 * 60 * 1000);
      setStartDate(last1Hour);
      setEndDate(now)
      updateTimeDate(last1Hour);
      handleClose();
    };

    return <MenuItem onClick={handleClick}>Last 1 Hour</MenuItem>;
  };

  const Last24Hours = ({ setStartDate }) => {
    const handleClick = () => {
      const now = new Date();
      const last24Hours = new Date(now.getTime() - 24 * 60 * 60 * 1000);
      setStartDate(last24Hours);
      setEndDate(now)
      updateTimeDate(last24Hours);
      handleClose();
    };

    return <MenuItem onClick={handleClick}>Last 24 Hours</MenuItem>;
  };

  const Last7DaysButton = ({ setStartDate }) => {
    const handleClick = () => {
      const date = new Date();
      date.setDate(date.getDate() - 7);
      date.setHours(0, 0, 0, 0);
      setStartDate(date);
      setEndDate(now)
      updateTimeDate(date);
      handleClose();
    };

    return <MenuItem onClick={handleClick}>Last 7 Days</MenuItem>;
  };

  const Last30DaysButton = ({ setStartDate }) => {
    const handleClick = () => {
      const now = new Date();
      const last30Days = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
      setStartDate(last30Days);
      setEndDate(now)
      updateTimeDate(last30Days);
      handleClose();
    };

    return <MenuItem onClick={handleClick}>Last 30 Days</MenuItem>;
  };

  const Last90DaysButton = ({ setStartDate }) => {
    const handleClick = () => {
      const now = new Date();
      const last90Days = new Date(now.getTime() - 90 * 24 * 60 * 60 * 1000);
      setStartDate(last90Days);
      setEndDate(now)
      updateTimeDate(last90Days);
      handleClose();
    };

    return <MenuItem onClick={handleClick}>Last 90 Days</MenuItem>;
  };

  const Last1YearButton = ({ setStartDate }) => {
    const handleClick = () => {
      const now = new Date();
      const last1Year = new Date(now.getTime() - 365 * 24 * 60 * 60 * 1000);
      setStartDate(last1Year);
      setEndDate(now)
      updateTimeDate(last1Year);
      handleClose();
    };

    return <MenuItem onClick={handleClick}>Last 1 Year</MenuItem>;
  };

  const [selectedLevelType, setSelectedLevelType] = useState(null);

  const updateTimeDate = (start) => {
    const selected_location = location[0];

    // const newJsonWithLoc = {
    //   // "collapse": { "field": "rule.id" },
    //   "size": 10000,
    //   "sort": [{ "timestamp": "desc" }],
    //   "query": {
    //     "bool": {
    //       "filter": [
    //         { "match_phrase": { "agent.name": selected_location } },
    //         { "range": { "timestamp": { "gte": start, "lt": enddate } } }
    //       ]
    //     }
    //   },
    //   "aggs": {
    //     "rule_level_aggregation": {
    //       "terms": {
    //         "field": "rule.level",
    //         "size": 20
    //       }
    //     }
    //   }
    // };

    // const newJsonWithoutLoc = {
    //   // "collapse": { "field": "rule.id" },
    //   "size": 10000,
    //   "sort": [{ "timestamp": "desc" }],
    //   "query": {
    //     "bool": {
    //       "filter": [
    //         { "range": { "timestamp": { "gte": start, "lt": enddate } } }
    //       ]
    //     }
    //   },
    //   "aggs": {
    //     "rule_level_aggregation": {
    //       "terms": {
    //         "field": "rule.level",
    //         "size": 20
    //       }
    //     }
    //   }
    // };
    const combinedJson = {
      // "collapse": { "field": "rule.id" },
      "size": 10000,
      "sort": [{ "timestamp": "desc" }],
      "query": {
        "bool": {
          "filter": [
            ...(selected_location ? [{ "match_phrase": { "agent.name": selected_location } }] : []),
            { "range": { "timestamp": { "gte": start, "lt": enddate } } },
            {
              "terms": {
                "rule.id": [
                  301001, 301004, 301005, 301006, 301002, 301003, 301007, 301008, 100601, 18110, 60109, 18111, 18112, 60111, 18116, 18118, 60117, 63103, 18127, 
                   60121, 60132, 60133, 60138, 60139, 60784, 60785, 63104, 92213, 92655, 92064, 92104, 18130, 18138, 60923, 18128, 5105, 5106, 5111, 5112, 
                   5109, 5110, 5113, 5130, 5134, 5135, 5137, 5138, 5141, 5104, 5103, 80731, 80732, 5132, 5133, 5108, 2901, 2902, 2903, 2904, 2942, 2944, 2941, 
                   2945, 2932, 2934, 2937, 2938, 2939, 80725, 80722, 80723, 80726, 92601, 92603, 92604, 92605, 80713, 80714, 80702, 80704, 80711, 80721, 80731, 
                   80732, 80740, 92602, 504, 505, 506, 601, 603, 605, 651, 653, 655, 100100, 100101, 100102, 100103, 100106, 100107, 100108, 100109, 100110, 100111, 
                   100112, 100113, 100114, 100115, 100116, 100117, 100122, 100123, 100124, 100125, 100118, 100119, 100120, 100121, 100130, 100131, 100132, 100133, 
                   519, 531, 533, 580, 513, 593, 4810, 64025, 80127, 81620, 81631, 80129, 81613, 658, 4101, 81634, 90554, 90564, 67005, 67006, 67007, 67008, 81605, 81615, 
                   81617, 4339, 4342, 4508, 4509, 64023, 64024, 64027, 91520, 91521, 91522, 91525, 4811, 4151, 4551, 4851, 87702, 100017, 100018, 100014, 100015, 100016, 
                   100019, 11209, 92044, 7310, 7320, 7410, 7504, 7509, 7510, 7610, 7613, 7705, 7707, 7708, 40113, 62113, 62114, 62115, 62122, 62123, 62126, 62131, 62144, 
                   62149, 62152, 62153, 62157, 62160, 62174, 62176, 62178, 62606, 62609, 62612, 62616, 63603, 65500, 65501, 83001, 87101, 87102, 87103, 87105, 100303, 
                   100306, 100310, 100314, 100327, 100328, 100329, 100330, 100331, 100332, 100333, 100334, 100335, 100336, 100337, 100338, 100339, 100340, 100341, 
                   100342, 100343, 100344, 100345, 100346, 100347, 100348, 100349, 100350, 100351, 100352, 100353, 100354, 100355, 100356, 100358, 100360, 100362, 
                   100363, 100364, 100365, 100366, 31101, 31120, 31122, 31315, 30107, 30118, 30200, 30201, 31516, 31502, 31503, 31504, 31505, 31506, 31507, 31508, 31512, 
                   31513, 30112, 31168, 31169, 31154, 31161, 31162, 31163, 31533, 31151, 31152, 31153, 31105, 91542, 91543, 91545, 91610, 91613, 91616, 91719, 91547, 91548, 
                   91557, 91566, 91570, 91579, 91612, 91620, 91704, 91705, 91708, 91715, 91569, 91589, 91605, 91615, 91622, 91724, 91556, 91575, 91720, 91722, 91700, 80326, 
                   80327, 80361, 80442, 80456, 80457, 99080, 99081, 99082, 80203, 80250, 80254, 80328, 80368, 80370, 99026, 80252, 80369, 80371, 80443, 99027, 99028, 99029, 
                   99030, 99021, 87802, 87811, 87813, 91545, 91709, 91711, 91714, 65053, 65054, 65055, 65056, 65057, 65058, 65059, 65060, 65061, 65062, 65063, 65064, 65067, 
                   65068, 65069, 65072, 65073, 65023, 65048, 65031, 65037, 65008, 65015, 65044, 65018, 65024, 65027, 65030, 65010, 65017, 65020, 65046, 50108, 50511, 85756, 
                   85758, 85759, 85752, 88101, 85761, 50512, 50580, 50581, 85760, 50520, 50521, 1004, 1006, 2501, 2504, 2502, 1003, 1001, 1002, 12101, 12109, 40105, 40106, 
                   100013, 40104, 5707, 5714, 40102, 40501, 2963, 2833, 5901, 5902, 5904, 11251, 12149, 40111, 40112, 91100, 91111, 91169, 91361, 91448, 65601, 65615, 91170, 
                   91110, 91151, 91164, 91184, 91222, 91228, 91249, 91250, 91254, 91255, 91256, 91264, 91277, 91399, 91402, 91418, 91103, 91363, 91241, 91315, 91367, 61618, 
                   61627, 61628, 61630, 61636, 184666, 184706, 184716, 184736, 184746, 184776, 99553, 99585, 99628, 99632, 99635, 99636, 99637, 99506, 99534, 99538, 99606, 
                   99566, 99576, 99626, 99630, 99530, 99536, 99564, 99631, 99653, 23503, 22402, 22407, 23504, 22405, 23505, 22406, 23506, 19113, 19103, 19111, 19123, 19102, 
                   19151, 19153, 100501, 100502, 100126, 100127, 100128, 100129
                ]
              }
            }
          ]
        }
      },
      "aggs": {
        "rule_level_aggregation": {
          "terms": {
            "field": "rule.level",
            "size": 20
          }
        }
      }
    };

    setLoading(true);
    const data = JSON.stringify(combinedJson);

    // setLoading(true);
    // var data = JSON.stringify(selected_location ? newJsonWithLoc : newJsonWithoutLoc);

    var config = {
      method: 'post',
      url: BaseUrl + AlertsList,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        setFilterCount(response.data.aggregations.rule_level_aggregation.buckets);
        setData(response.data.hits.hits.map(element => {
          const timestamp = new Date(element._source.timestamp).toLocaleString();
          return {
            id: element._source.id,
            agent_id: element._source.agent?.id || '',
            timestamp: timestamp,
            agent_name: element._source.agent?.name || '',
            description: element._source.rule?.description || '',
            level: element._source.rule?.level || '',
            ruleid: element._source.rule?.id || '',
            rule_info: element._source.rule?.mitre?.technique || '',
            agent_ip: element._source.agent?.ip || '',
            agent_os: element._source.agent?.labels?.os_type || '',
            eventID: element._source.data?.win?.system?.eventID || '',
            system_level: element._source.data?.win?.system?.level || ''
          }
        }))
        setTotalRows(response.data.hits.hits.length);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });

    setFilterJson(combinedJson);
  }


  const updateDate = () => {
    const selected_location = location[0];
    let rangeFilter = null;

    switch (selectedLevelType) {
      case "greaterThan12":
        rangeFilter = { "gte": 11 };
        break;
      case "between8to12":
        rangeFilter = { "gte": 8, "lte": 10 };
        break;
      case "between4to7":
        rangeFilter = { "gte": 4, "lte": 7 };
        break;
      case "between1to3":
        rangeFilter = { "gte": 1, "lte": 3 };
        break;
      case "equalto0":
        rangeFilter = { "gte": 0, "lte": 0 };
        break;
      default:
        break;
    }

    const filters = [
      ...(selected_location ? [{ "match_phrase": { "agent.name": selected_location } }] : []),
      { "range": { "timestamp": { "gte": startdate, "lt": enddate } } },
      {
        "terms": {
          "rule.id": [
            301001, 301004, 301005, 301006, 301002, 301003, 301007, 301008, 100601, 18110, 60109, 18111, 18112, 60111, 18116, 18118, 60117, 63103, 18127, 
                   60121, 60132, 60133, 60138, 60139, 60784, 60785, 63104, 92213, 92655, 92064, 92104, 18130, 18138, 60923, 18128, 5105, 5106, 5111, 5112, 
                   5109, 5110, 5113, 5130, 5134, 5135, 5137, 5138, 5141, 5104, 5103, 80731, 80732, 5132, 5133, 5108, 2901, 2902, 2903, 2904, 2942, 2944, 2941, 
                   2945, 2932, 2934, 2937, 2938, 2939, 80725, 80722, 80723, 80726, 92601, 92603, 92604, 92605, 80713, 80714, 80702, 80704, 80711, 80721, 80731, 
                   80732, 80740, 92602, 504, 505, 506, 601, 603, 605, 651, 653, 655, 100100, 100101, 100102, 100103, 100106, 100107, 100108, 100109, 100110, 100111, 
                   100112, 100113, 100114, 100115, 100116, 100117, 100122, 100123, 100124, 100125, 100118, 100119, 100120, 100121, 100130, 100131, 100132, 100133, 
                   519, 531, 533, 580, 513, 593, 4810, 64025, 80127, 81620, 81631, 80129, 81613, 658, 4101, 81634, 90554, 90564, 67005, 67006, 67007, 67008, 81605, 81615, 
                   81617, 4339, 4342, 4508, 4509, 64023, 64024, 64027, 91520, 91521, 91522, 91525, 4811, 4151, 4551, 4851, 87702, 100017, 100018, 100014, 100015, 100016, 
                   100019, 11209, 92044, 7310, 7320, 7410, 7504, 7509, 7510, 7610, 7613, 7705, 7707, 7708, 40113, 62113, 62114, 62115, 62122, 62123, 62126, 62131, 62144, 
                   62149, 62152, 62153, 62157, 62160, 62174, 62176, 62178, 62606, 62609, 62612, 62616, 63603, 65500, 65501, 83001, 87101, 87102, 87103, 87105, 100303, 
                   100306, 100310, 100314, 100327, 100328, 100329, 100330, 100331, 100332, 100333, 100334, 100335, 100336, 100337, 100338, 100339, 100340, 100341, 
                   100342, 100343, 100344, 100345, 100346, 100347, 100348, 100349, 100350, 100351, 100352, 100353, 100354, 100355, 100356, 100358, 100360, 100362, 
                   100363, 100364, 100365, 100366, 31101, 31120, 31122, 31315, 30107, 30118, 30200, 30201, 31516, 31502, 31503, 31504, 31505, 31506, 31507, 31508, 31512, 
                   31513, 30112, 31168, 31169, 31154, 31161, 31162, 31163, 31533, 31151, 31152, 31153, 31105, 91542, 91543, 91545, 91610, 91613, 91616, 91719, 91547, 91548, 
                   91557, 91566, 91570, 91579, 91612, 91620, 91704, 91705, 91708, 91715, 91569, 91589, 91605, 91615, 91622, 91724, 91556, 91575, 91720, 91722, 91700, 80326, 
                   80327, 80361, 80442, 80456, 80457, 99080, 99081, 99082, 80203, 80250, 80254, 80328, 80368, 80370, 99026, 80252, 80369, 80371, 80443, 99027, 99028, 99029, 
                   99030, 99021, 87802, 87811, 87813, 91545, 91709, 91711, 91714, 65053, 65054, 65055, 65056, 65057, 65058, 65059, 65060, 65061, 65062, 65063, 65064, 65067, 
                   65068, 65069, 65072, 65073, 65023, 65048, 65031, 65037, 65008, 65015, 65044, 65018, 65024, 65027, 65030, 65010, 65017, 65020, 65046, 50108, 50511, 85756, 
                   85758, 85759, 85752, 88101, 85761, 50512, 50580, 50581, 85760, 50520, 50521, 1004, 1006, 2501, 2504, 2502, 1003, 1001, 1002, 12101, 12109, 40105, 40106, 
                   100013, 40104, 5707, 5714, 40102, 40501, 2963, 2833, 5901, 5902, 5904, 11251, 12149, 40111, 40112, 91100, 91111, 91169, 91361, 91448, 65601, 65615, 91170, 
                   91110, 91151, 91164, 91184, 91222, 91228, 91249, 91250, 91254, 91255, 91256, 91264, 91277, 91399, 91402, 91418, 91103, 91363, 91241, 91315, 91367, 61618, 
                   61627, 61628, 61630, 61636, 184666, 184706, 184716, 184736, 184746, 184776, 99553, 99585, 99628, 99632, 99635, 99636, 99637, 99506, 99534, 99538, 99606, 
                   99566, 99576, 99626, 99630, 99530, 99536, 99564, 99631, 99653, 23503, 22402, 22407, 23504, 22405, 23505, 22406, 23506, 19113, 19103, 19111, 19123, 19102, 
                   19151, 19153, 100501, 100502, 100126, 100127, 100128, 100129
          ]
        }
      },
      ...(rangeFilter ? [{ "range": { "rule.level": rangeFilter } }] : [])
    ];

    const newJsonWithLoc = {
      "size": 10000,
      "sort": [{ "timestamp": "desc" }],
      "query": {
        "bool": {
          "filter": filters
        }
      },
      "aggs": {
        "rule_level_aggregation": {
          "terms": {
            "field": "rule.level",
            "size": 20
          }
        }
      }
    };

    setLoading(true);
    const data = JSON.stringify(newJsonWithLoc);

    const config = {
      method: 'post',
      url: BaseUrl + AlertsList,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        setFilterCount(response.data.aggregations.rule_level_aggregation.buckets);
        setData(response.data.hits.hits.map(element => {
          const timestamp = new Date(element._source.timestamp).toLocaleString();
          return {
            id: element._source.id,
            agent_id: element._source.agent?.id || '',
            timestamp: timestamp,
            agent_name: element._source.agent?.name || '',
            description: element._source.rule?.description || '',
            level: element._source.rule?.level || '',
            ruleid: element._source.rule?.id || '',
            rule_info: element._source.rule?.mitre?.technique || '',
            agent_ip: element._source.agent?.ip || '',
            agent_os: element._source.agent?.labels?.os_type || '',
            eventID: element._source.data?.win?.system?.eventID || '',
            system_level: element._source.data?.win?.system?.level || ''
          };
        }));
        setTotalRows(response.data.hits.hits.length);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });

    setFilterJson(newJsonWithLoc);
  };

  const resetDate = () => {
    const startd = new Date(Date.now() - 24 * 60 * 60 * 1000);
    const endd = new Date().toISOString();

    const newJson = {
      // "collapse": { "field": "rule.id" },
      "size": 10000,
      "sort": [
        { "timestamp": "desc" }
      ],
      "query": {
        "bool": {
          "filter":
            [
              {
                "range": {
                  "timestamp": {
                    "gte": startd,
                    "lt": endd
                  }
                }
              },
              {
                "terms": {
                  "rule.id": [
                    301001, 301004, 301005, 301006, 301002, 301003, 301007, 301008, 100601, 18110, 60109, 18111, 18112, 60111, 18116, 18118, 60117, 63103, 18127, 
                    60121, 60132, 60133, 60138, 60139, 60784, 60785, 63104, 92213, 92655, 92064, 92104, 18130, 18138, 60923, 18128, 5105, 5106, 5111, 5112, 
                    5109, 5110, 5113, 5130, 5134, 5135, 5137, 5138, 5141, 5104, 5103, 80731, 80732, 5132, 5133, 5108, 2901, 2902, 2903, 2904, 2942, 2944, 2941, 
                    2945, 2932, 2934, 2937, 2938, 2939, 80725, 80722, 80723, 80726, 92601, 92603, 92604, 92605, 80713, 80714, 80702, 80704, 80711, 80721, 80731, 
                    80732, 80740, 92602, 504, 505, 506, 601, 603, 605, 651, 653, 655, 100100, 100101, 100102, 100103, 100106, 100107, 100108, 100109, 100110, 100111, 
                    100112, 100113, 100114, 100115, 100116, 100117, 100122, 100123, 100124, 100125, 100118, 100119, 100120, 100121, 100130, 100131, 100132, 100133, 
                    519, 531, 533, 580, 513, 593, 4810, 64025, 80127, 81620, 81631, 80129, 81613, 658, 4101, 81634, 90554, 90564, 67005, 67006, 67007, 67008, 81605, 81615, 
                    81617, 4339, 4342, 4508, 4509, 64023, 64024, 64027, 91520, 91521, 91522, 91525, 4811, 4151, 4551, 4851, 87702, 100017, 100018, 100014, 100015, 100016, 
                    100019, 11209, 92044, 7310, 7320, 7410, 7504, 7509, 7510, 7610, 7613, 7705, 7707, 7708, 40113, 62113, 62114, 62115, 62122, 62123, 62126, 62131, 62144, 
                    62149, 62152, 62153, 62157, 62160, 62174, 62176, 62178, 62606, 62609, 62612, 62616, 63603, 65500, 65501, 83001, 87101, 87102, 87103, 87105, 100303, 
                    100306, 100310, 100314, 100327, 100328, 100329, 100330, 100331, 100332, 100333, 100334, 100335, 100336, 100337, 100338, 100339, 100340, 100341, 
                    100342, 100343, 100344, 100345, 100346, 100347, 100348, 100349, 100350, 100351, 100352, 100353, 100354, 100355, 100356, 100358, 100360, 100362, 
                    100363, 100364, 100365, 100366, 31101, 31120, 31122, 31315, 30107, 30118, 30200, 30201, 31516, 31502, 31503, 31504, 31505, 31506, 31507, 31508, 31512, 
                    31513, 30112, 31168, 31169, 31154, 31161, 31162, 31163, 31533, 31151, 31152, 31153, 31105, 91542, 91543, 91545, 91610, 91613, 91616, 91719, 91547, 91548, 
                    91557, 91566, 91570, 91579, 91612, 91620, 91704, 91705, 91708, 91715, 91569, 91589, 91605, 91615, 91622, 91724, 91556, 91575, 91720, 91722, 91700, 80326, 
                    80327, 80361, 80442, 80456, 80457, 99080, 99081, 99082, 80203, 80250, 80254, 80328, 80368, 80370, 99026, 80252, 80369, 80371, 80443, 99027, 99028, 99029, 
                    99030, 99021, 87802, 87811, 87813, 91545, 91709, 91711, 91714, 65053, 65054, 65055, 65056, 65057, 65058, 65059, 65060, 65061, 65062, 65063, 65064, 65067, 
                    65068, 65069, 65072, 65073, 65023, 65048, 65031, 65037, 65008, 65015, 65044, 65018, 65024, 65027, 65030, 65010, 65017, 65020, 65046, 50108, 50511, 85756, 
                    85758, 85759, 85752, 88101, 85761, 50512, 50580, 50581, 85760, 50520, 50521, 1004, 1006, 2501, 2504, 2502, 1003, 1001, 1002, 12101, 12109, 40105, 40106, 
                    100013, 40104, 5707, 5714, 40102, 40501, 2963, 2833, 5901, 5902, 5904, 11251, 12149, 40111, 40112, 91100, 91111, 91169, 91361, 91448, 65601, 65615, 91170, 
                    91110, 91151, 91164, 91184, 91222, 91228, 91249, 91250, 91254, 91255, 91256, 91264, 91277, 91399, 91402, 91418, 91103, 91363, 91241, 91315, 91367, 61618, 
                    61627, 61628, 61630, 61636, 184666, 184706, 184716, 184736, 184746, 184776, 99553, 99585, 99628, 99632, 99635, 99636, 99637, 99506, 99534, 99538, 99606, 
                    99566, 99576, 99626, 99630, 99530, 99536, 99564, 99631, 99653, 23503, 22402, 22407, 23504, 22405, 23505, 22406, 23506, 19113, 19103, 19111, 19123, 19102, 
                    19151, 19153, 100501, 100502, 100126, 100127, 100128, 100129
                  ]
                }
              }
            ]
        }
      },
      "aggs": {
        "rule_level_aggregation": {
          "terms": {
            "field": "rule.level",
            "size": 20
          }
        }
      }
    };

    setLoading(true);
    var data = JSON.stringify(newJson);

    var config = {
      method: 'post',
      url: BaseUrl + AlertsList,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        setFilterCount(response.data.aggregations.rule_level_aggregation.buckets);
        setData(response.data.hits.hits.map(element => {
          const timestamp = new Date(element._source.timestamp).toLocaleString();
          return {
            id: element._source.id,
            agent_id: element._source.agent?.id || '',
            timestamp: timestamp,
            agent_name: element._source.agent?.name || '',
            description: element._source.rule?.description || '',
            level: element._source.rule?.level || '',
            ruleid: element._source.rule?.id || '',
            rule_info: element._source.rule?.mitre?.technique || '',
            agent_ip: element._source.agent?.ip || '',
            agent_os: element._source.agent?.labels?.os_type || '',
            eventID: element._source.data?.win?.system?.eventID || '',
            system_level: element._source.data?.win?.system?.level || ''
          }
        }))
        setTotalRows(response.data.hits.hits.length);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
    setFilterJson(newJson);
    setLocation([]);
    setStartDate(new Date(Date.now() - 24 * 60 * 60 * 1000));
    setEndDate(new Date().toISOString());
    setLevelGreaterThan12(false)
    setLevelBetween8to12(false)
    setLevelBetween4to7(false)
    setLevelBetween1to3(false);
    setLevelEqualto0(false);
    setPage(0);
    setSelectedLevelType(null);
    setToken(tenant_access_switched ? tenant_access_switched : user_details.data.accessToken)
    setSelectedTenant("");
    window.location.reload();
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const names = dropdownlist.map(item => (item.key))

  function getStyles(name, location, theme) {
    return {
      fontWeight:
        location.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const theme = useTheme();
  const [location, setLocation] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setLocation(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  React.useEffect(() => {
    if (location.length > 0) {
      updateDate();
    }
  }, [location]);

  const [levelgreaterthan12, setLevelGreaterThan12] = useState(false);
  const [levelbetween8to12, setLevelBetween8to12] = useState(false);
  const [levelbetween4to7, setLevelBetween4to7] = useState(false);
  const [levelbetween1to3, setLevelBetween1to3] = useState(false);
  const [levelequalto0, setLevelEqualto0] = useState(false);

  function handleCheckboxChange(event, levelType) {
    setLevelGreaterThan12(false);
    setLevelBetween8to12(false);
    setLevelBetween4to7(false);
    setLevelBetween1to3(false);
    setLevelEqualto0(false);


    switch (levelType) {
      case "greaterThan12":
        setLevelGreaterThan12(event.target.checked);
        setSelectedLevelType(levelType);
        break;
      case "between8to12":
        setLevelBetween8to12(event.target.checked);
        setSelectedLevelType(levelType);
        break;
      case "between4to7":
        setLevelBetween4to7(event.target.checked);
        setSelectedLevelType(levelType);
        break;
      case "between1to3":
        setLevelBetween1to3(event.target.checked);
        setSelectedLevelType(levelType);
        break;
      case "equalto0":
        setLevelEqualto0(event.target.checked);
        setSelectedLevelType(levelType);
        break;
      default:
        break;
    }

    if (event.target.checked) {
      let rangeFilter;

      switch (levelType) {
        case "greaterThan12":
          rangeFilter = { "gte": 11 };
          break;
        case "between8to12":
          rangeFilter = { "gte": 8, "lte": 10 };
          break;
        case "between4to7":
          rangeFilter = { "gte": 4, "lte": 7 };
          break;
        case "between1to3":
          rangeFilter = { "gte": 1, "lte": 3 };
          break;
        case "equalto0":
          rangeFilter = { "gte": 0, "lte": 0 };
          break;
        default:
          break;
      }

      const locationString = location[0] ? location[0] : names;

      const newJsonWithLoc = {
        "size": 10000,
        "sort": [{ "timestamp": "desc" }],
        "query": {
          "bool": {
            "filter": [
              { "range": { "rule.level": rangeFilter } },
              ...(locationString && Array.isArray(locationString)
                ? [{ "terms": { "agent.name": locationString } }]
                : locationString
                  ? [{ "match_phrase": { "agent.name": locationString } }]
                  : []),
              { "range": { "timestamp": { "gte": startdate, "lt": enddate } } },
              {
                "terms": {
                  "rule.id": [
                    301001, 301004, 301005, 301006, 301002, 301003, 301007, 301008, 100601, 18110, 60109, 18111, 18112, 60111, 18116, 18118, 60117, 63103, 18127, 
                   60121, 60132, 60133, 60138, 60139, 60784, 60785, 63104, 92213, 92655, 92064, 92104, 18130, 18138, 60923, 18128, 5105, 5106, 5111, 5112, 
                   5109, 5110, 5113, 5130, 5134, 5135, 5137, 5138, 5141, 5104, 5103, 80731, 80732, 5132, 5133, 5108, 2901, 2902, 2903, 2904, 2942, 2944, 2941, 
                   2945, 2932, 2934, 2937, 2938, 2939, 80725, 80722, 80723, 80726, 92601, 92603, 92604, 92605, 80713, 80714, 80702, 80704, 80711, 80721, 80731, 
                   80732, 80740, 92602, 504, 505, 506, 601, 603, 605, 651, 653, 655, 100100, 100101, 100102, 100103, 100106, 100107, 100108, 100109, 100110, 100111, 
                   100112, 100113, 100114, 100115, 100116, 100117, 100122, 100123, 100124, 100125, 100118, 100119, 100120, 100121, 100130, 100131, 100132, 100133, 
                   519, 531, 533, 580, 513, 593, 4810, 64025, 80127, 81620, 81631, 80129, 81613, 658, 4101, 81634, 90554, 90564, 67005, 67006, 67007, 67008, 81605, 81615, 
                   81617, 4339, 4342, 4508, 4509, 64023, 64024, 64027, 91520, 91521, 91522, 91525, 4811, 4151, 4551, 4851, 87702, 100017, 100018, 100014, 100015, 100016, 
                   100019, 11209, 92044, 7310, 7320, 7410, 7504, 7509, 7510, 7610, 7613, 7705, 7707, 7708, 40113, 62113, 62114, 62115, 62122, 62123, 62126, 62131, 62144, 
                   62149, 62152, 62153, 62157, 62160, 62174, 62176, 62178, 62606, 62609, 62612, 62616, 63603, 65500, 65501, 83001, 87101, 87102, 87103, 87105, 100303, 
                   100306, 100310, 100314, 100327, 100328, 100329, 100330, 100331, 100332, 100333, 100334, 100335, 100336, 100337, 100338, 100339, 100340, 100341, 
                   100342, 100343, 100344, 100345, 100346, 100347, 100348, 100349, 100350, 100351, 100352, 100353, 100354, 100355, 100356, 100358, 100360, 100362, 
                   100363, 100364, 100365, 100366, 31101, 31120, 31122, 31315, 30107, 30118, 30200, 30201, 31516, 31502, 31503, 31504, 31505, 31506, 31507, 31508, 31512, 
                   31513, 30112, 31168, 31169, 31154, 31161, 31162, 31163, 31533, 31151, 31152, 31153, 31105, 91542, 91543, 91545, 91610, 91613, 91616, 91719, 91547, 91548, 
                   91557, 91566, 91570, 91579, 91612, 91620, 91704, 91705, 91708, 91715, 91569, 91589, 91605, 91615, 91622, 91724, 91556, 91575, 91720, 91722, 91700, 80326, 
                   80327, 80361, 80442, 80456, 80457, 99080, 99081, 99082, 80203, 80250, 80254, 80328, 80368, 80370, 99026, 80252, 80369, 80371, 80443, 99027, 99028, 99029, 
                   99030, 99021, 87802, 87811, 87813, 91545, 91709, 91711, 91714, 65053, 65054, 65055, 65056, 65057, 65058, 65059, 65060, 65061, 65062, 65063, 65064, 65067, 
                   65068, 65069, 65072, 65073, 65023, 65048, 65031, 65037, 65008, 65015, 65044, 65018, 65024, 65027, 65030, 65010, 65017, 65020, 65046, 50108, 50511, 85756, 
                   85758, 85759, 85752, 88101, 85761, 50512, 50580, 50581, 85760, 50520, 50521, 1004, 1006, 2501, 2504, 2502, 1003, 1001, 1002, 12101, 12109, 40105, 40106, 
                   100013, 40104, 5707, 5714, 40102, 40501, 2963, 2833, 5901, 5902, 5904, 11251, 12149, 40111, 40112, 91100, 91111, 91169, 91361, 91448, 65601, 65615, 91170, 
                   91110, 91151, 91164, 91184, 91222, 91228, 91249, 91250, 91254, 91255, 91256, 91264, 91277, 91399, 91402, 91418, 91103, 91363, 91241, 91315, 91367, 61618, 
                   61627, 61628, 61630, 61636, 184666, 184706, 184716, 184736, 184746, 184776, 99553, 99585, 99628, 99632, 99635, 99636, 99637, 99506, 99534, 99538, 99606, 
                   99566, 99576, 99626, 99630, 99530, 99536, 99564, 99631, 99653, 23503, 22402, 22407, 23504, 22405, 23505, 22406, 23506, 19113, 19103, 19111, 19123, 19102, 
                   19151, 19153, 100501, 100502, 100126, 100127, 100128, 100129
                  ]
                }
              }
            ]
          }
        },
        "aggs": {
          "rule_level_aggregation": {
            "terms": {
              "field": "rule.level",
              "size": 20
            }
          }
        }
      };


      setLoading(true);
      const data = JSON.stringify(newJsonWithLoc);

      const config = {
        method: 'post',
        url: BaseUrl + AlertsList,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then(function (response) {
          // setFilterCount(response.data.aggregations.rule_level_aggregation.buckets);
          setData(response.data.hits.hits.map(element => {
            const timestamp = new Date(element._source.timestamp).toLocaleString();
            return {
              id: element._source.id,
              agent_id: element._source.agent?.id || '',
              timestamp: timestamp,
              agent_name: element._source.agent?.name || '',
              description: element._source.rule?.description || '',
              level: element._source.rule?.level || '',
              ruleid: element._source.rule?.id || '',
              rule_info: element._source.rule?.mitre?.technique || '',
              agent_ip: element._source.agent?.ip || '',
              agent_os: element._source.agent?.labels?.os_type || '',
              eventID: element._source.data?.win?.system?.eventID || '',
              system_level: element._source.data?.win?.system?.level || ''
            };
          }));
          setTotalRows(response.data.hits.hits.length);
          setLoading(false);
        })
        .catch(function (error) {
          console.log(error);
        });

      setFilterJson(newJsonWithLoc);
    } else {
      resetDate();
    }
  }

  const getCountForKeys = (keys) => {
    return filtercount
      .filter(item => keys.includes(item.key))
      .reduce((acc, curr) => acc + curr.doc_count, 0);
  };

  const criticalCount = getCountForKeys([11, 12, 13, 14, 15]);
  const highCount = getCountForKeys([8, 9, 10]);
  const mediumCount = getCountForKeys([4, 5, 6, 7]);
  const lowCount = getCountForKeys([1, 2, 3]);
  const infoCount = getCountForKeys([0]);

  const [users, setUsers] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState("");

  useEffect(() => {
    const loadUsers = async () => {
      try {
        const userData = await fetchUsers();
        setUsers(userData);
      } catch (error) {
        console.error("Failed to fetch users", error);
      }
    };

    loadUsers();
  }, []);

  const handleSelectChange = async (event) => {
    const tenantName = event.target.value;
    setSelectedTenant(tenantName);

    try {
      const response = await axios.post(
        BaseUrl + '/auth/iKzmfludhj',
        { tenant: tenantName },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        }
      );
      if (response.data.status === "error") {
        const this_token = tenant_access_switched ? tenant_access_switched : user_details.data.accessToken
        const response = await axios.post(
          BaseUrl + '/auth/iKzmfludhj',
          { tenant: tenantName },
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${this_token}`,
            },
          }
        );
        setToken(response.data.user_atoken.accessToken);
      } else {
        setToken(response.data.user_atoken.accessToken);
      }

    } catch (error) {
      console.error("Error calling API:", error);
    }
  };

  return (
    <div>
      <div style={{ height: "7vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
            flexWrap: "nowrap",
            background: "#f9f9f9",
            padding: 2,
            borderRadius: 2,
            boxShadow: 3,
            overflowX: "auto",
          }}
        >
          <Box sx={{ display: "flex", gap: 1 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "6px 12px",
                borderRadius: 20,
                backgroundColor: "#f44336",
                color: "#fff",
                minWidth: 80,
                textAlign: "center",
                fontSize: "12px",
                cursor: "pointer",
              }}
              onClick={() => handleCheckboxChange({ target: { checked: !levelgreaterthan12 } }, "greaterThan12")}
            >
              {levelgreaterthan12 && (
                <CheckCircle sx={{ marginRight: 1 }} />
              )}
              Critical ({criticalCount || 0})
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "6px 12px",
                borderRadius: 20,
                backgroundColor: "#ff9800",
                color: "#fff",
                minWidth: 80,
                textAlign: "center",
                fontSize: "12px",
                cursor: "pointer",
              }}
              onClick={() => handleCheckboxChange({ target: { checked: !levelbetween8to12 } }, "between8to12")}
            >
              {levelbetween8to12 && (
                <CheckCircle sx={{ marginRight: 1 }} />
              )}
              High ({highCount || 0})
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "6px 12px",
                borderRadius: 20,
                backgroundColor: "#03a9f4",
                color: "#fff",
                minWidth: 80,
                textAlign: "center",
                fontSize: "12px",
                cursor: "pointer",
              }}
              onClick={() => handleCheckboxChange({ target: { checked: !levelbetween4to7 } }, "between4to7")}
            >
              {levelbetween4to7 && (
                <CheckCircle sx={{ marginRight: 1 }} />
              )}
              Medium ({mediumCount || 0})
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "6px 12px",
                borderRadius: 20,
                backgroundColor: "#8bc34a",
                color: "#fff",
                minWidth: 80,
                textAlign: "center",
                fontSize: "12px",
                cursor: "pointer",
              }}
              onClick={() => handleCheckboxChange({ target: { checked: !levelbetween1to3 } }, "between1to3")}
            >
              {levelbetween1to3 && (
                <CheckCircle sx={{ marginRight: 1 }} />
              )}
              Low ({lowCount || 0})
            </Box>
          </Box>

          <FormControl sx={{ minWidth: 250 }} size="small">
            <InputLabel id="demo-multiple-chip-label">Agents</InputLabel>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              // multiple
              value={location}
              onChange={handleChange}
              input={<OutlinedInput id="select-multiple-chip" label="Agents" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} size="small" />
                  ))}
                </Box>
              )}
            >
              {names.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {
            role === "user_soc" &&
              (!localStorage.getItem("selectedtenant") || og_tenant === localStorage.getItem("selectedtenant")) ? (
              <FormControl sx={{ minWidth: 250 }} size="small">
                <InputLabel id="company-label">Select Company</InputLabel>
                <Select
                  labelId="company-label"
                  value={selectedTenant}
                  onChange={handleSelectChange}
                  input={<OutlinedInput label="Select Company" />}
                >
                  {users.map((user) => (
                    <MenuItem key={user.tenantName} value={user.tenantName}>
                      {user.company_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null
          }
          <Button
            variant="outlined"
            startIcon={<FilterAltOutlinedIcon />}
            onClick={handleClick}
            size="medium"
            sx={{ color: "#1976d2", borderColor: "#1976d2" }}
          >
            Quick Select
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <TodayButton setStartDate={setStartDate} />
            <ThisWeek setStartDate={setStartDate} />
            <Last15MinsButton setStartDate={setStartDate} />
            <Last30MinsButton setStartDate={setStartDate} />
            <Last1Hour setStartDate={setStartDate} />
            <Last24Hours setStartDate={setStartDate} />
            <Last7DaysButton setStartDate={setStartDate} />
            <Last30DaysButton setStartDate={setStartDate} />
            <Last90DaysButton setStartDate={setStartDate} />
            <Last1YearButton setStartDate={setStartDate} />
          </Menu>

          <Box sx={{ display: "flex", gap: 1 }}>
            {/* <Button
              variant="contained"
              onClick={updateDate}
              size="medium"
              startIcon={<CheckCircleOutlineOutlinedIcon />}
              sx={{ backgroundColor: "#4caf50" }}
            >
              Apply
            </Button> */}
            <Button
              variant="outlined"
              onClick={resetDate}
              size="medium"
              startIcon={<RefreshOutlinedIcon />}
              sx={{ color: "#f44336", borderColor: "#f44336" }}
            >
              Reset
            </Button>
          </Box>
        </Box>
      </div>
      <br />
      <Card>
        <Box style={{ height: 400, width: '100%' }}>
          <style>
            {`
              .MuiDataGrid-columnHeader {
                background-color: green;
                color: white;
              }
              .MuiDataGrid-cell {
                color: black;
              }
              .MuiDataGrid-footerContainer {
                background-color: lightgray;
              }
              .MuiDataGrid-row:nth-of-type(odd) {
                background-color: lightyellow;
              }
              .MuiDataGrid-row:nth-of-type(even) {
                background-color: white;
              }
            `}
          </style>
          <DataGrid
            rows={data}
            rowCount={totalRows}
            loading={loading}
            rowsPerPageOptions={[10]}
            rowHeight={30}
            pagination
            page={page}
            pageSize={pageSize}
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            columns={columns}
          />
        </Box>
      </Card>

      <Dialog open={isModalOpen} onClose={closeModal} maxWidth="sm" fullWidth>
        {/* Header with Close Button on Right */}
        <DialogTitle>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography variant="h6">Alert Details</Typography>
            <Button onClick={closeModal} variant="outlined" color="error" size="small">
              Close
            </Button>
          </Box>
        </DialogTitle>

        <DialogContent dividers>
          {selectedAlert ? (
            <Table
              sx={{
                "& .MuiTableCell-root": {
                  padding: "4px 8px",
                  fontSize: "12px",
                  border: "1px solid #ccc",
                },
                "& .MuiTableRow-root": {
                  height: "24px",
                },
              }}
            >
              <TableBody>
                <TableRow>
                  <TableCell><strong>Date Time:</strong></TableCell>
                  <TableCell>{selectedAlert.timestamp || "N/A"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Agent ID:</strong></TableCell>
                  <TableCell>{selectedAlert.agent_id || "N/A"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Agent Name:</strong></TableCell>
                  <TableCell>{selectedAlert.agent_name || "N/A"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Agent IP:</strong></TableCell>
                  <TableCell>{selectedAlert.agent_ip || "N/A"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>OS Type:</strong></TableCell>
                  <TableCell>{selectedAlert.agent_os || "N/A"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Rule ID:</strong></TableCell>
                  <TableCell>{selectedAlert.ruleid || "N/A"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Level:</strong></TableCell>
                  <TableCell>{selectedAlert.level || "N/A"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Event ID:</strong></TableCell>
                  <TableCell>{selectedAlert.eventID || "N/A"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Description:</strong></TableCell>
                  <TableCell>{selectedAlert.description || "N/A"}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          ) : (
            <p>Loading...</p>
          )}
        </DialogContent>
      </Dialog>

    </div>
  );
}

export default Alerts;