import React, { useState } from 'react'
import axios, * as others from 'axios';
import { Card, Box, Button, Typography, Tooltip } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Toggle from './Toggle';
import { BaseUrl } from '../constants/constants';

const Ar = () => {
  
  const columns = [
    { 
      field: 'timestamp', 
      headerName: 'Date Time',  
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.value}>
            <div>{params.value}</div>
        </Tooltip>
      )
    },
    { 
      field: 'agent_name', 
      headerName: 'Agent Name',  
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.value}>
            <div>{params.value}</div>
        </Tooltip>
      )
    },
    { 
      field: 'ruleid', 
      headerName: 'Rule Id',
      renderCell: (params) => (
        <Tooltip title={params.value}>
            <div>{params.value}</div>
        </Tooltip>
      )
    },
    // { 
    //   field: 'rule_info', 
    //   headerName: 'Rule Info',  
    //   flex: 1,
    //   renderCell: (params) => (
    //     <Tooltip title={params.value}>
    //         <div>{params.value}</div>
    //     </Tooltip>
    //   )
    // },
    { 
      field: 'level', 
      headerName: 'Level',
      renderCell: (params) => (
        <Tooltip title={params.value}>
            <div>{params.value}</div>
        </Tooltip>
      )
    },
    { 
      field: 'description', 
      headerName: 'Description',  
      flex: 1.5,
      renderCell: (params) => (
        <Tooltip title={params.value}>
            <div>{params.value}</div>
        </Tooltip>
      )
    }
  ];

  const user_details = JSON.parse(window.localStorage.getItem("user_details"));
  const token = user_details.data.accessToken
  
  const [page, setPage] = React.useState(0);
  const [data, setData] = React.useState({});
  const [pageSize, setPageSize] = React.useState(10);
	const [loading, setLoading] = React.useState(false);
	const [totalRows, setTotalRows] = React.useState(0);

  const now = new Date().toISOString();

  const [startdate, setStartDate] = React.useState(dayjs(new Date(Date.now() - 24 * 60 * 60 * 1000)));
  const [enddate, setEndDate] = React.useState(dayjs(now));

  React.useEffect(() => {
		fetchData('');
		
	}, []);

  const [filterjson, setFilterJson] = useState(
    {
      "size":1000,
       "sort": [
                {"timestamp": "desc"}
            ],
        "query": {
            
          "bool": {
            "filter": [
              { "term": { "decoder.name": "ar_log_json"   }},
              {
                "range": {
                    "timestamp": {
                        "gte": startdate,
                        "lt": enddate
                    }
                }
              }
            ]
          }
        }
      }
  );

  const fetchData = async page => {
    setLoading(true);
    var data = JSON.stringify(filterjson);

    var config = {
      method: 'post',
      url: BaseUrl + '/connectors/workstation/alerts' + page,
      headers: { 
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios(config)
    .then(function (response) {
      setData(response.data.hits.hits.map(element => {
        const timestamp = new Date(element._source.timestamp).toLocaleString();
        return {
          "id" : element._source.id,
          "timestamp" : timestamp,
          "agent_name":element._source.agent.name,
          "description" : element._source.rule.description,
          "level" : element._source.rule.level,
          "ruleid" : element._source.rule.id,
          "rule_info" : element._source.rule.mitre?.technique
        }
      }))
      setTotalRows(response.data.hits.hits.length);
      setLoading(false);
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  const updateDate = () => {
    const newJson = {
      "size":1000,
       "sort": [
                {"timestamp": "desc"}
            ],
        "query": {
            
          "bool": {
            "filter": [
              { "term": { "decoder.name": "ar_log_json"   }},
              {  "range": {
                "timestamp": {
                  "gte": startdate,
                  "lt": enddate
                  }
                }
              }
            ]
          }
        }
      };
      
      setLoading(true);
    var data = JSON.stringify(newJson);

    var config = {
      method: 'post',
      url: BaseUrl + '/connectors/workstation/alerts',
      headers: { 
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios(config)
    .then(function (response) {
      setData(response.data.hits.hits.map(element => {
        const timestamp = new Date(element._source.timestamp).toLocaleString();
        return {
          "id" : element._source.id,
          "timestamp" : timestamp,
          "agent_name":element._source.agent.name,
          "description" : element._source.rule.description,
          "level" : element._source.rule.level,
          "ruleid" : element._source.rule.id,
          "rule_info" : element._source.rule.mitre?.technique
        }
      }))
      setTotalRows(response.data.hits.hits.length);
      setLoading(false);
    })
    .catch(function (error) {
      console.log(error);
    });
    setFilterJson(newJson);
  }

  const resetDate = () => {
    const newJson = {
      "size":1000,
       "sort": [
                {"timestamp": "desc"}
            ],
        "query": {
            
          "bool": {
            "filter": [
              { "term": { "decoder.name": "ar_log_json"   }}
            ]
          }
        }
      };
      
      setLoading(true);
    var data = JSON.stringify(newJson);

    var config = {
      method: 'post',
      url: BaseUrl + '/connectors/workstation/alerts',
      headers: { 
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios(config)
    .then(function (response) {
      setData(response.data.hits.hits.map(element => {
        const timestamp = new Date(element._source.timestamp).toLocaleString();
        return {
          "id" : element._source.id,
          "timestamp" : timestamp,
          "agent_name":element._source.agent.name,
          "description" : element._source.rule.description,
          "level" : element._source.rule.level,
          "ruleid" : element._source.rule.id,
          "rule_info" : element._source.rule.mitre?.technique
        }
      }))
      setTotalRows(response.data.hits.hits.length);
      setLoading(false);
    })
    .catch(function (error) {
      console.log(error);
    });
    setFilterJson(newJson);
    setStartDate(new Date(Date.now() - 24 * 60 * 60 * 1000));
    setEndDate(new Date().toISOString());
  }

  return (
    <>
    <Toggle />
    <div className='ar-date-picker'>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
      size={'small'}
        renderInput={(props) => <TextField {...props} size={'small'}/>}
        label="Start Date"
        value={startdate}
        onChange={(newValue) => {
          setStartDate(newValue);
        }}
      />
    </LocalizationProvider>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        renderInput={(props) => <TextField {...props} size={'small'}/>}
        label="End Date"
        size={'small'}
        value={enddate}
        onChange={(newValue) => {
          setEndDate(newValue);
        }}
      />
    </LocalizationProvider>
    </div>
    <div className='ar-apply-btn'>
      <Button variant="contained" onClick={updateDate} size={'small'}>
        Apply
      </Button>
    </div>
    <div className='ar-reset-btn'>
      <Button variant="contained" onClick={resetDate} size={'small'}>
        Reset
      </Button>
    </div>
    <br />
    <Card>
    
        <Box  style={{ height: 410, width: '100%' }}>
        <style>
        {`
          .MuiDataGrid-columnHeader {
            background-color: green;
            color: white;
          }
          .MuiDataGrid-cell {
            color: black;
          }
          .MuiDataGrid-footerContainer {
            background-color: lightgray;
          }
          .MuiDataGrid-row:nth-of-type(odd) {
            background-color: lightyellow;
          }
          .MuiDataGrid-row:nth-of-type(even) {
            background-color: white;
          }
        `}
      </style>
      <DataGrid
        rows={data}
        rowCount={totalRows}
        loading={loading}
        rowsPerPageOptions={[10]}
        rowHeight={30}
        pagination
        page={page}
        pageSize={pageSize}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        columns={columns}
      />
    </Box>
    </Card>
    </>
  )
}

export default Ar