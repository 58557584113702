import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { IconButton, Button, Box, Typography, TextField } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { useNavigate } from 'react-router-dom';
import { BaseUrl } from '../constants/constants';

const Generate_Report = () => {
  const [reports, setReports] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const user_details = JSON.parse(window.localStorage.getItem("user_details"));
  const token = user_details.data.accessToken;
  const navigate = useNavigate();
  const user_role = window.localStorage.getItem("user_role");

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: BaseUrl + '/reports/generated_reports/',
          headers: {
            'Authorization': `Bearer ${token}`
          },
        };
  
        const response = await axios.request(config);
        const allReports = response.data.list_gen_report;
  
        const sortedReports = allReports.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
  
        setReports(sortedReports);
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchReports();
  }, [token]);  

  const handleDownload = async (fileName) => {
    const data = JSON.stringify({ "report_file_name": fileName });

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: BaseUrl + '/reports/generated_reports/download',
      headers: { 
        'Content-Type': 'application/json', 
        'Authorization': `Bearer ${token}`
      },
      data: data
    };

    try {
      const response = await axios.request(config);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  const formatCreatedAt = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const timeOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3 };
    return `${date.toLocaleDateString('en-US', options)} @ ${date.toLocaleTimeString('en-US', timeOptions)}`;
  };

  const formatFileSize = (sizeInBytes) => {
    return (sizeInBytes / 1024).toFixed(2) + ' KB';
  };

  const extractDescription = (fileName) => {
    const parts = fileName.split('_');
    return parts.slice(0, -2).join(' ');
  };

  const columns = [
    { field: 'fileName', headerName: 'File', flex: 1, cellClassName: 'word-wrap',
      valueGetter: (params) => extractDescription(params.row.fileName)
    },
    { 
      field: 'fileSize', 
      headerName: 'Size', 
      flex: 0.5, 
      valueGetter: (params) => formatFileSize(params.row.fileSize)
    },
    { 
      field: 'createdAt', 
      headerName: 'Created', 
      flex: 1, 
      valueGetter: (params) => formatCreatedAt(params.row.createdAt)
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      renderCell: (params) => (
        <IconButton
          onClick={() => handleDownload(params.row.fileName)}
        >
          <DownloadIcon 
          sx={{ 
            color: 'info.main',
            fontSize: 35
          }}
          />
        </IconButton>
      ),
      flex: 0.5,
    },
  ];

  const filteredReports = reports.filter((report) => 
    report.fileName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div style={{ height: '75vh', width: '100%' }}>
      <style>
        {`
          .MuiDataGrid-columnHeader {
            background-color: green;
            color: white;
          }
          .MuiDataGrid-cell {
            color: black;
          }
          .MuiDataGrid-footerContainer {
            background-color: lightgray;
          }
          .MuiDataGrid-row:nth-of-type(odd) {
            background-color: lightyellow;
          }
          .MuiDataGrid-row:nth-of-type(even) {
            background-color: white;
          }
        `}
      </style>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} pr={5}>
        <Typography variant="h5" component="h1" style={{ marginTop: '1%', marginLeft: '1%' }}>
          Generated Reports
        </Typography>
        <TextField 
          label="Search by File Name" 
          variant="outlined" 
          size="small" 
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ marginTop: '1%', width: '500px' }}
        />
        <Button 
          variant="outlined" 
          color="primary"
          onClick={() => navigate('/dashboard/configure_reports')}
          style={{ marginTop: '1%' }}
          disabled={user_role === 'user_ro'}
        >
          Reports Configuration
        </Button>
      </Box>
      <DataGrid
        rows={filteredReports}
        columns={columns}
        getRowId={(row) => row.fileName}
        pageSize={10}
        rowsPerPageOptions={[10]}
      />
    </div>
  );
};

export default Generate_Report;