import React, { useState, useEffect } from 'react';

const AlertDiscover = () => {
    const selected_tenant = localStorage.getItem("selectedtenant");
      const [tenantname, setTenantname] = useState(
        selected_tenant || JSON.parse(window.localStorage.getItem("tenant_name"))
    );

    const [kibanaToken, setKibanaToken] = useState(null);

    useEffect(() => {
        const tenant_ktoken = localStorage.getItem("tenant_token");
        const user_details = JSON.parse(window.localStorage.getItem("user_details"));
        const kibanaToken = tenant_ktoken ? tenant_ktoken : user_details.data.kibana_token;

        if (tenantname && kibanaToken) {
            setTenantname(tenantname);
            setKibanaToken(kibanaToken);
        }
    }, []);

    return (
        <>
            {tenantname && kibanaToken && (
                <div style={{ width: '100%', height: 'calc(100vh - 20px)', display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    <iframe
                        src={`https://saas.khika.in/kibana/app/discover?security_tenant=${tenantname}&auth_token=${kibanaToken}#/?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))&_a=(columns:!(_source),filters:!(),index:'4b58b330-6509-11ef-94a5-9d045b0c62c4',interval:auto,query:(language:kuery,query:''),sort:!())&embed=true&show-time-filter=true`}
                        style={{ width: '100%', height: '100%', flexGrow: 1 }}
                        title="Discover"
                        frameBorder="0"
                    ></iframe>
                </div>
            )}
        </>
    );
};

export default AlertDiscover;