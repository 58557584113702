import { AppBar, Box, Button, IconButton, Menu, MenuItem, Toolbar, Tooltip, Typography, ListItemIcon } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as React from 'react';
import logo from '../images/khikalogo.png';
import { Link } from "react-router-dom";
import AccountPopover from '../components/AccountPopover';

const Navbar = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = React.useState({});

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSubMenuAnchorEl({});
  };

  const handleSubMenuClick = (event, menuName) => {
    setSubMenuAnchorEl((prev) => ({ ...prev, [menuName]: event.currentTarget }));
  };

  const handleSubMenuClose = (menuName) => {
    setSubMenuAnchorEl((prev) => ({ ...prev, [menuName]: null }));
  };

  let user = props.UserData;

  const tenantname = JSON.parse(window.localStorage.getItem("tenant_name"));
  
  return (
    <>
      <AppBar style={{ background: 'white' }} position="sticky" sx={{ flexGrow: 1 }}>
        <Toolbar style={{ background: 'white' }} >
          <Typography sx={{ flexGrow: 1 }}>
            <img src={logo} className='headers-logo' alt="logo" sx={{ flexGrow: 1 }} />
          </Typography>
          <Button style={{ color: 'black', fontWeight: 'bold' }}>
            <Link reloadDocument to="/dashboard/home" style={{ textDecoration: 'none', color: 'black', textTransform: 'capitalize' }}>Home</Link>
          </Button>
          {/* <Button
            style={{ color: 'black', fontWeight: 'bold', textTransform: 'capitalize' }}
            onClick={handleMenuClick}
          >
            Modules
          </Button> */}
          <Button style={{ color: 'black', fontWeight: 'bold' }}>
            <Link reloadDocument to="/dashboard/connectors" style={{ textDecoration: 'none', color: 'black', textTransform: 'capitalize' }}>Connectors</Link>
          </Button>
          <Button style={{ color: 'black', fontWeight: 'bold' }}>
            <Link to="/dashboard/alerts" style={{ textDecoration: 'none', color: 'black', textTransform: 'capitalize' }}>Alerts</Link>
          </Button>
          <Button style={{ color: 'black', fontWeight: 'bold', textTransform: 'capitalize' }}>
            <Link to="/dashboard/cis" style={{ textDecoration: 'none', color: 'black', textTransform: 'capitalize' }}>CIS</Link>
          </Button>
          <Button style={{ color: 'black', fontWeight: 'bold', textTransform: 'capitalize' }}>
            <Link to="/dashboard/data_management" style={{ textDecoration: 'none', color: 'black', textTransform: 'capitalize' }}>Data Management</Link>
          </Button>
          <Button style={{ color: 'black', fontWeight: 'bold', textTransform: 'capitalize' }}>
            <Link to="/dashboard/manageusers" style={{ textDecoration: 'none', color: 'black', textTransform: 'capitalize' }}>Manage Users</Link>
          </Button>

          {
            tenantname === "a2hpa2etmte5" && (
              <>
                <Button style={{ color: 'black', fontWeight: 'bold', textTransform: 'capitalize' }} onClick={(event) => handleSubMenuClick(event, 'dataInsights')}>
                  Data Insights
                  <ListItemIcon>
                    <ExpandMoreIcon />
                  </ListItemIcon>
                </Button>
                <Menu
                  anchorEl={subMenuAnchorEl.dataInsights}
                  open={Boolean(subMenuAnchorEl.dataInsights)}
                  onClose={() => handleSubMenuClose('dataInsights')}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                >
                  {/* <MenuItem onClick={handleMenuClose}>
                    <Link to="/dashboard/datainsights" style={{ textDecoration: 'none', color: 'black' }} onClick={handleMenuClose}>Data Insights</Link>
                  </MenuItem> */}
                  <MenuItem onClick={handleMenuClose}>
                    <Link to="/dashboard/mlsearch" style={{ textDecoration: 'none', color: 'black' }} onClick={handleMenuClose}>Data Insights</Link>
                  </MenuItem>
                  <MenuItem onClick={handleMenuClose}>
                    <Link to="/dashboard/logsearch" style={{ textDecoration: 'none', color: 'black' }} onClick={handleMenuClose}>Log Search</Link>
                  </MenuItem>
                </Menu>
                <Button style={{ color: 'black', fontWeight: 'bold', textTransform: 'capitalize' }}>
                  <Link to="/dashboard/chatbot" style={{ textDecoration: 'none', color: 'black', textTransform: 'capitalize' }}>AI Troubleshoot</Link>
                </Button>
              </>
            )
          }
          
          {/* Reporting Button */}
          <Button style={{ color: 'black', fontWeight: 'bold', textTransform: 'capitalize' }} onClick={(event) => handleSubMenuClick(event, 'reporting')}>
            Reports
            <ListItemIcon>
              <ExpandMoreIcon />
            </ListItemIcon>
          </Button>
          <Menu
            anchorEl={subMenuAnchorEl.reporting}
            open={Boolean(subMenuAnchorEl.reporting)}
            onClose={() => handleSubMenuClose('reporting')}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            <MenuItem onClick={handleMenuClose}>
              <Link to="/dashboard/reporting" style={{ textDecoration: 'none', color: 'black' }} onClick={handleMenuClose}>Module Reports</Link>
            </MenuItem>
            {/* <MenuItem onClick={handleMenuClose}>
              <Link to="/dashboard/reports_dashboard" style={{ textDecoration: 'none', color: 'black' }} onClick={handleMenuClose}>Custom Reports</Link>
            </MenuItem> */}
            <MenuItem onClick={handleMenuClose}>
              <Link to="/dashboard/generate_reports" style={{ textDecoration: 'none', color: 'black' }} onClick={handleMenuClose}>Generate Reports</Link>
            </MenuItem>
          </Menu>

          {/* Modules Button */}
          
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            MenuListProps={{ 'aria-labelledby': 'modules-button' }}
          >
            {/* Security Management Submenu */}
            <MenuItem onClick={(event) => handleSubMenuClick(event, 'securityManagement')}>
              Security Management
              <ListItemIcon>
                <ExpandMoreIcon />
              </ListItemIcon>
            </MenuItem>
            <Menu
              anchorEl={subMenuAnchorEl.securityManagement}
              open={Boolean(subMenuAnchorEl.securityManagement)}
              onClose={() => handleSubMenuClose('securityManagement')}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
              <MenuItem onClick={handleMenuClose}>
                <Link to="/dashboard/modules/security-management/security-event" style={{ textDecoration: 'none', color: 'black' }} onClick={handleMenuClose}>Security Event</Link>
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <Link to="/dashboard/modules/security-management/integrity-monitoring" style={{ textDecoration: 'none', color: 'black' }} onClick={handleMenuClose}>Integrity Monitoring</Link>
              </MenuItem>
            </Menu>

            {/* Auditing/Policy Monitoring Submenu */}
            <MenuItem onClick={(event) => handleSubMenuClick(event, 'auditingPolicyMonitoring')}>
              Auditing/Policy Monitoring
              <ListItemIcon>
                <ExpandMoreIcon />
              </ListItemIcon>
            </MenuItem>
            <Menu
              anchorEl={subMenuAnchorEl.auditingPolicyMonitoring}
              open={Boolean(subMenuAnchorEl.auditingPolicyMonitoring)}
              onClose={() => handleSubMenuClose('auditingPolicyMonitoring')}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
              <MenuItem onClick={handleMenuClose}>
                <Link to="/dashboard/modules/auditing-policy-monitoring/policy-monitoring" style={{ textDecoration: 'none', color: 'black' }} onClick={handleMenuClose}>Policy Monitoring</Link>
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <Link to="/dashboard/modules/auditing-policy-monitoring/system-auditing" style={{ textDecoration: 'none', color: 'black' }} onClick={handleMenuClose}>System Auditing</Link>
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <Link to="/dashboard/modules/auditing-policy-monitoring/security-configuration" style={{ textDecoration: 'none', color: 'black' }} onClick={handleMenuClose}>Security Configuration</Link>
              </MenuItem>
            </Menu>

            {/* Threat Detection Submenu */}
            <MenuItem onClick={(event) => handleSubMenuClick(event, 'threatDetection')}>
              Threat Detection
              <ListItemIcon>
                <ExpandMoreIcon />
              </ListItemIcon>
            </MenuItem>
            <Menu
              anchorEl={subMenuAnchorEl.threatDetection}
              open={Boolean(subMenuAnchorEl.threatDetection)}
              onClose={() => handleSubMenuClose('threatDetection')}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
              <MenuItem onClick={handleMenuClose}>
                <Link to="/dashboard/modules/threat-detection/vulnerabilities" style={{ textDecoration: 'none', color: 'black' }} onClick={handleMenuClose}>Vulnerabilities</Link>
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <Link to="/dashboard/modules/threat-detection/mitre-attack" style={{ textDecoration: 'none', color: 'black' }} onClick={handleMenuClose}>Mitre Attack</Link>
              </MenuItem>
            </Menu>

            {/* Regulatory Compliance Submenu */}
            <MenuItem onClick={(event) => handleSubMenuClick(event, 'regulatoryCompliance')}>
              Regulatory Compliance
              <ListItemIcon>
                <ExpandMoreIcon />
              </ListItemIcon>
            </MenuItem>
            <Menu
              anchorEl={subMenuAnchorEl.regulatoryCompliance}
              open={Boolean(subMenuAnchorEl.regulatoryCompliance)}
              onClose={() => handleSubMenuClose('regulatoryCompliance')}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
              <MenuItem onClick={handleMenuClose}>
                <Link to="/dashboard/modules/regulatory-compliance/pci-dss" style={{ textDecoration: 'none', color: 'black' }} onClick={handleMenuClose}>PCI DSS</Link>
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <Link to="/dashboard/modules/regulatory-compliance/nist-800-53" style={{ textDecoration: 'none', color: 'black' }} onClick={handleMenuClose}>NIST 800-53</Link>
              </MenuItem>
              {/* <MenuItem onClick={handleMenuClose}>
                <Link to="/dashboard/modules/regulatory-compliance/tsc" style={{ textDecoration: 'none', color: 'black' }}>TSC</Link>
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <Link to="/dashboard/modules/regulatory-compliance/gdpr" style={{ textDecoration: 'none', color: 'black' }}>GDPR</Link>
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <Link to="/dashboard/modules/regulatory-compliance/hipaa" style={{ textDecoration: 'none', color: 'black' }}>HIPAA</Link>
              </MenuItem> */}
            </Menu>
          </Menu>

          <AccountPopover UserData={user} />
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Navbar;